<template>
  <div>
    <datatable
      ref="titulosDatatable"
      @row-action="rowAction"
      :customFields="fields"
      :customLastRow="lastRowData"
      :showExportToExcell="true"
      :exportToExcellAction="downloadTitlesXlsx"
    >
      <template #table-header-actions>
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-between align-items-center mr-2">
            <v-select
              v-model="selectedAssignor"
              :options="assignors"
              placeholder="Cedente"
              class="assignor-selector d-flex flex-column mr-1"
            >
            </v-select>

            <flat-pickr
              v-model="data_vencimento_de"
              class="assignor-selector form-control bg-white mr-1"
              :config="config"
              placeholder="Data de vencimento inicial"
            />

            <flat-pickr
              v-model="data_vencimento_ate"
              class="assignor-selector form-control bg-white mr-1"
              :config="config"
              placeholder="Data de vencimento final"
            />

            <b-button variant="outline-secondary" @click="clearDates" class="py-50 px-75">
              <cc-icon icon="CustomEraserIcon" class="text-dark" />
            </b-button>
          </div>

          <v-select
            v-model="selectedStatus"
            :options="statuses"
            placeholder="Status"
            class="assignor-selector d-flex flex-column mr-1"
          >
          </v-select>

          <feather-icon
            icon="FilterIcon"
            size="24"
            class="cursor-pointer text-secondary ml-1"
            @click="showFiltersButton = true"
          />
        </div>
      </template>
    </datatable>

    <pay-modal :selectedItem="selectedItem" />
    <update-deadline-modal :selectedItem="selectedItem" />
    <cancel-modal :selectedItem="selectedItem" />
    <decrease-value-modal :selectedItem="selectedItem" />

    <datatable-filters-handler-sidebar
      :is-task-handler-sidebar-active="showFiltersButton"
      @closeSideBar="showFiltersButton = false"
      @updateFilterValues="updateFilterValues"
      :filters="customFilters"
    />
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Datatable from '@/views/common/crud/components/Datatable'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import {
  tituloStatusBadge,
  moneyFormatDatatable,
  brazilianFormattedDate,
  tituloStatusLiquidacaoBadge,
} from '@core/comp-functions/data_visualization/datatable'
import CcIcon from '@/@core/components/cc-icon/CcIcon.vue'
import PayModal from '@/views/admin/titulos/components/PayModal'
import CancelModal from '@/views/admin/titulos/components/CancelModal'
import DecreaseValueModal from '@/views/admin/titulos/components/DecreaseValueModal'
import UpdateDeadlineModal from '@/views/admin/titulos/components/UpdateDeadlineModal'
import DatatableFiltersHandlerSidebar from '@/views/common/crud/components/DatatableFiltersHandlerSidebar.vue'
import downloadFile from '@/utils/download-file'

export default {
  name: 'Concessoes',
  components: {
    CcIcon,
    BButton,
    vSelect,
    PayModal,
    flatPickr,
    Datatable,
    CancelModal,
    DecreaseValueModal,
    UpdateDeadlineModal,
    DatatableFiltersHandlerSidebar,
  },
  filters: {
    moneyFormat: moneyFormatDatatable,
    brazilianFormattedDate,
  },
  data() {
    return {
      showFiltersButton: false,
      datatableFilterValues: {},
      selectedItem: {},
      selectedAssignor: null,
      assignors: [],
      items: [],
      fields: [
        {
          label: 'Número',
          key: 'numero',
        },
        {
          label: 'Sacado',
          key: 'sacado',
        },
        {
          label: 'Cessão',
          key: 'data_cessao',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Vencimento',
          key: 'data_vencimento',
          formatter: brazilianFormattedDate,
        },
        {
          label: 'Valor face',
          key: 'valor_face',
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Valor operado',
          key: 'valor_operado',
          formatter: moneyFormatDatatable,
        },
        {
          label: 'Dias operados',
          key: 'prazo',
        },
        {
          label: 'Tipo',
          key: 'descricao_tipo',
        },
        {
          label: 'Status',
          key: 'status_liquidacao',
          formatter: tituloStatusLiquidacaoBadge,
        },
        {
          label: '',
          key: 'titleActions',
        },
      ],
      lastRowData: [
        // {
        //   index: 5,
        //   key: 'data.valor_face_total',
        //   formatter: moneyFormatDatatable,
        //   _variant: 'font-weight-bolder',
        // },
        // {
        //   index: 6,
        //   key: 'data.valor_operado_total',
        //   formatter: moneyFormatDatatable,
        //   _variant: 'font-weight-bolder',
        // },
      ],
      customFilters: [
        {
          category: 'Por parâmetro',
          fields: [
            {
              label: 'Número',
              key: 'numero',
              type: 'text',
            },
            {
              label: 'Sacado',
              key: 'sacado',
              type: 'text'
            },
          ],
        },
      ],
      data_vencimento_de: '',
      data_vencimento_ate: '',
      selectedStatus: null,
      statuses: [
        { value: 'aguardando_pagamento', label: 'Aguardando pagamento' },
        { value: 'pago', label: 'Pago' },
        { value: 'atrasado', label: 'Atrasado' },
        { value: 'cancelado', label: 'Cancelado' },
        { value: 'perdido', label: 'Perdido' },
      ],
      config: {
        locale: Portuguese,
        dateFormat: 'd/m/Y',
      },
    }
  },
  async mounted() {
    this.getAssignors()
  },
  methods: {
    async getAssignors() {
      const data = await this.$store.dispatch('admin/getAssignors')
      this.assignors = data.map((assignor) => ({
        value: assignor.id,
        label: assignor.nome,
      }))
    },
    updateFilterValues(values) {
      this.datatableFilterValues = values
    },
    clearDates() {
      this.data_vencimento_de = ''
      this.data_vencimento_ate = ''
    },
    rowAction({ action, payload }) {
      const actions = {
        pay: 'payModal',
        cancel: 'cancelModal',
        decreaseValue: 'decreaseValueModal',
        updateDeadline: 'updateDeadlineModal',
      }
      return this[actions[action]](payload)
    },
    async payModal(payload) {
      this.selectedItem = payload
      await this.$bvModal.show('payModal')
    },
    async updateDeadlineModal(payload) {
      this.selectedItem = payload
      await this.$bvModal.show('updateDeadlineModal')
    },
    async cancelModal(payload) {
      this.selectedItem = payload
      await this.$bvModal.show('cancelModal')
    },
    async decreaseValueModal(payload) {
      this.selectedItem = payload
      await this.$bvModal.show('decreaseValueModal')
    },
    async downloadTitlesXlsx() {
      try {
        const { data } = await this.$store.dispatch('admin/exportTitles', this.$refs.titulosDatatable.params)
        downloadFile(data, 'titulos.xlsx', 'text/xlsx')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Ocorreu um erro ao tentar baixa o arquivo.'
        })
      }
    }
  },
  computed: {
    params() {
      let params = {}

      if (this.selectedAssignor) params.cedente_id = this.selectedAssignor.value
      if (this.data_vencimento_de) params.data_vencimento_de = this.data_vencimento_de
      if (this.data_vencimento_ate) params.data_vencimento_ate = this.data_vencimento_ate
      if (this.selectedStatus) params.status_liquidacao = this.selectedStatus.value
      if (this.datatableFilterValues) params = { ...params, ...this.datatableFilterValues }

      return params
    },
  },
  watch: {
    params(newValue, oldValue) {
      if (newValue !== oldValue) this.$refs.titulosDatatable.updateFilterValues(newValue)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.assignor-selector {
  min-width: 200px;
}
</style>
